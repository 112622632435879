import config from "../config";

let paths = [
  {
    path: "/",
    name: "Home",
    view: "Home",
    component: () => import(/* webpackChunkName: "home" */ `@/views/Home.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleHome" }
  },
  {
    path: "/about",
    name: "About",
    view: "About",
    component: () => import(/* webpackChunkName: "about" */ `@/views/About.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleAbout" }
  },
  {
    path: "/signup",
    name: "Signup",
    view: "Signup",
    component: () => import(/* webpackChunkName: "signup" */ `@/views/Signup.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleSignUp" }
  },
  {
    path: "/login",
    name: "Login",
    view: "Login",
    component: () => import(/* webpackChunkName: "login" */ `@/views/Login.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleLogin" }
  },
  {
    path: "/contact",
    name: "Contact",
    view: "FooterPages/ContactUs",
    component: () => import(/* webpackChunkName: "contactus" */ `@/views/FooterPages/ContactUs.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleContactUs" }
  },
  {
    path: "/news/:infoid",
    name: "News",
    component: () => import(`@/views/News.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleNews" }
  },
  {
    path: "/terms",
    name: "Terms",
    view: "/FooterPages/Terms",
    component: () => import(`@/views/FooterPages/Terms.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleTerms" }
  },
  {
    path: "/terms/:side",
    name: "TermsCondition",
    view: "/FooterPages/Terms",
    component: () => import(/* webpackChunkName: "terms" */ `@/views/FooterPages/Terms.vue`),
    meta: { requiresAuth: false, searchable: false, translationKey: "titleTerms" }
  },
  {
    path: "/privacy",
    name: "Privacy",
    view: "/FooterPages/Privacy",
    component: () => import(/* webpackChunkName: "privacy" */ `@/views/FooterPages/Privacy.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titlePrivacy" }
  },
  {
    path: "/cookies",
    name: "Cookies",
    view: "/FooterPages/Cookies",
    component: () => import(/* webpackChunkName: "cookies" */ `@/views/FooterPages/Cookies.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleCookies" }
  },
  // {
  //   path: "/tutorials",
  //   name: "Tutorials",
  //   view: "/FooterPages/Tutorials",
  //   component: () => import(/* webpackChunkName: "tutorials" */`@/views/FooterPages/Tutorials.vue`),
  //   meta: { requiresAuth: false, searchable: true }
  // },
  {
    path: "/questions",
    name: "Questions",
    view: "/Questions",
    component: () => import(/* webpackChunkName: "questions" */ `@/views/Questions.vue`),
    meta: { requiresAuth: false, searchable: true, translationKey: "titleFAQ" }
  },
  {
    path: "/platform/mycollection",
    name: "My Collection",
    view: "/Platform/mycollection",
    component: () => import(/* webpackChunkName: "mycollection" */ `@/views/Platform/MyCollection.vue`),
    meta: { requiresAuth: true, searchable: true, translationKey: "titleMyCollection" }
  },
  {
    path: "/platform/new",
    name: "New Artwork",
    component: () => import(/* webpackChunkName: "mycollection" */ `@/views/Platform/AddArtwork.vue`),
    meta: { requiresAuth: true, searchable: true, translationKey: "titleNewWork" }
  },
  {
    path: "/platform/edit/:workid",
    name: "Edit Artwork",
    component: () => import(/* webpackChunkName: "mycollection" */ `@/views/Platform/AddArtwork.vue`),
    meta: { requiresAuth: true, searchable: true, translationKey: "titleEditWork" },
    props: { workid: null }
  },
  {
    path: "/platform/mycollection/:workid",
    name: "My Private Work",
    component: () => import(/* webpackChunkName: "viewartwork" */ `@/views/Platform/ViewArtwork.vue`),
    meta: { requiresAuth: true, searchable: true, translationKey: "titleMyWork" }
  },
  {
    path: "/platform/bazaar",
    name: "Bazaar",
    view: "/Platform/bazaar",
    component: () => import(/* webpackChunkName: "bazaar" */ `@/views/Platform/Bazaar.vue`),
    props: { eventName: "" },
    meta: { requiresAuth: false, searchable: true, translationKey: "titleBazaar" }
  },
  {
    path: "/platform/user/:userid",
    component: () => import(/* webpackChunkName: "viewartworkpublic" */ `@/views/UserBazaar.vue`),
    meta: { requiresAuth: false, searchable: false, translationKey: "titleUser" },
    props: true
  },
  {
    path: "/platform/artwork/:workid",
    name: "ViewArtwork",
    component: () => import(/* webpackChunkName: "viewartworkpublic" */ `@/views/Platform/ViewArtwork.vue`),
    meta: { requiresAuth: false, searchable: false, translationKey: "titleArtwork" }
  },
  // {
  //   path: "/platform/chat",
  //   name: "Chat",
  //   component: () => import(/* webpackChunkName: "chat" */ `@/views/Platform/Chatroom.vue`),
  //   meta: { requiresAuth: true, searchable: true, translationKey: "titleChat" }
  // },
  {
    path: "/platform/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/Platform/Profile.vue"),
    meta: { requiresAuth: true, searchable: true, translationKey: "titleProfile" }
  },
  // {
  //   path: "/platform/chat/:orderid",
  //   name: "Chatroom",
  //   component: () => import(/* webpackChunkName: "bargainpanel" */ `@/views/Platform/BargainPanel.vue`),
  //   meta: { requiresAuth: true, searchable: false, translationKey: "titleChatroom" }
  // },
  // {
  //   path: "/platform/contract/:orderid",
  //   name: "Contract",
  //   component: () => import(/* webpackChunkName: "contract" */ `@/views/Platform/Contract.vue`),
  //   meta: { requiresAuth: true, searchable: false, translationKey: "titleContract" }
  // },
  {
    path: "/platform/notifications",
    name: "Notifications Page",
    component: () => import(/* webpackChunkName: "notifications" */ `@/views/Platform/NotificationsPage.vue`),
    meta: { requiresAuth: true, searchable: true, translationKey: "titleNotification" }
  },
  // {
  //   path: "/events",
  //   name: "EventList",
  //   component: () => import(/* webpackChunkName: "eventcode" */ `@/views/EventsList.vue`),
  //   meta: { requiresAuth: false, searchable: false, translationKey: "titleEventCode" }
  // },
  {
    path: "/event/:eventCode",
    name: "Event",
    component: () => import(/* webpackChunkName: "eventcode" */ `@/views/Events.vue`),
    meta: { requiresAuth: false, searchable: false, translationKey: "titleEventCode" }
  },
  {
    path: "/event-works/:eventName",
    name: "Event Works",
    component: () => import(/* webpackChunkName: "eventcode" */ `@/views/EventBazaarPage.vue`),
    props: true,
    meta: { requiresAuth: false, searchable: false, translationKey: "titleEventName" }
  },
  {
    path: "/platform/admin/users",
    name: "Admin Users",
    component: () => import(/* webpackChunkName: "adminuser" */ `@/views/Platform/Admin/UserFunctions.vue`),
    meta: { requiresAuth: true, searchable: false, translationKey: "titleAdminUser" }
  },
  {
    path: "/platform/admin/works",
    name: "Admin Works",
    component: () => import(/* webpackChunkName: "adminwork" */ `@/views/Platform/Admin/WorkFunctions.vue`),
    meta: { requiresAuth: true, searchable: false, translationKey: "titleAdminWork" }
  },
  {
    path: "/platform/admin/events",
    name: "Admin Events",
    component: () => import(/* webpackChunkName: "adminevent" */ `@/views/Platform/Admin/EventFunctions.vue`),
    meta: { requiresAuth: true, searchable: false, translationKey: "titleAdminEvent" }
  },
  {
    path: "/platform/admin/referral",
    name: "Admin Referral",
    component: () => import(/* webpackChunkName: "adminreferral" */ `@/views/Platform/Admin/ReferralFunctions.vue`),
    meta: { requiresAuth: true, searchable: false, translationKey: "titleAdminReferral" }
  },
  {
    path: "/platform/admin/landing-info",
    name: "Admin Landing Info",
    component: () => import(/* webpackChunkName: "adminlanding" */ `@/views/Platform/Admin/LandingInfoFunctions.vue`),
    meta: { requiresAuth: true, searchable: false, translationKey: "titleAdminLanding" }
  },
  {
    path: "/platform/admin/landing-info/preview",
    name: "Admin Landing Info Preview",
    component: () => import(/* webpackChunkName: "adminlanding" */ `@/components/Home/NewsTemplate.vue`),
    props: { news: {} },
    meta: { requiresAuth: true, searchable: false, translationKey: "titleAdminLanding" }
  },
  {
    path: "/platform/admin/orders",
    name: "Admin Orders",
    component: () => import(/* webpackChunkName: "adminlanding" */ `@/views/Platform/Admin/OrderFunctions.vue`),
    meta: { requiresAuth: true, searchable: false, translationKey: "tectechainName" }
  },
  {
    path: "/platform/premium",
    name: "Upgrade to premium",
    component: () => import(/* webpackChunkName: "premium" */ `@/views/Platform/Premium.vue`),
    meta: { requiresAuth: true, searchable: true, translationKey: "titlePremium" }
  },
  {
    path: "/guide",
    name: "Guide",
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "premium" */ `@/views/Guide.vue`)
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "premium" */ `@/views/Maintenance.vue`)
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import(/* webpackChunkName: "notfound" */ `@/views/NotFound.vue`),
    meta: { requiresAuth: false, searchable: false, translationKey: "titleNotFound" }
  }
];

if (config.development_lock) {
  paths = paths.map((elem) => {
    elem.meta.requiresDevAuth = true;
    return elem;
  });
  paths.unshift({
    path: "/dev-login",
    name: "Development Login",
    component: () => import(/* webpackChunkName: "notfound" */ `@/views/Development/DevelopmentLogin.vue`),
    meta: { requiresAuth: false, searchable: false, requiresDevAuth: false }
  });
}

export default paths;
