export async function getDealList(axios, config, token, side) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.get(`${config.backend_api}/orders/${side}`, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getOrderByOrderID(axios, config, token, orderid) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.get(`${config.backend_api}/orders/info/${orderid}`, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function updateOrderInfo(axios, config, token, orderid, payload) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.put(`${config.backend_api}/orders/info/${orderid}`, payload, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function approveOrderInfo(axios, config, token, orderid, payload) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.put(`${config.backend_api}/orders/approve/${orderid}`, payload, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function signBySeller(axios, config, token, orderid, payload) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.put(`${config.backend_api}/orders/sign-by-seller/${orderid}`, payload, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function settleByBuyer(axios, config, token, orderid, payload) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.put(`${config.backend_api}/orders/settle-by-buyer/${orderid}`, payload, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function signByBuyer(axios, config, token, orderid, payload) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.put(`${config.backend_api}/orders/sign-by-buyer/${orderid}`, payload, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function payForService(axios, config, token, payload) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const result = await axios.post(`${config.backend_api}/payments/pay-for-service`, payload, opt);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function createStripePayment(axios, config, token, payload) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const result = await axios.post(`${config.backend_api}/payments/stripe/create`, payload, opt);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getStripePaymentIntent(axios, config, token, paymentIntentId) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const result = await axios.get(`${config.backend_api}/payments/stripe/paymentIntent/${paymentIntentId}`, opt);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function captureStripePayment(axios, config, token, paymentIntentId) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const result = await axios.put(
      `${config.backend_api}/payments/stripe/capture`,
      { intent_id: paymentIntentId },
      opt
    );

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}
