<template>
  <v-fade-transition>
    <v-overlay v-if="show" color="#ffffff" opacity="0.9">
      <v-progress-circular :value="value" color="primary" size="64" width="6"></v-progress-circular>
    </v-overlay>
  </v-fade-transition>
</template>

<script>
export default {
  data: () => ({
    value: 15,
    show: false,
    interval: {}
  }),
  created: function () {
    this.$store.watch(
      (state) => state.loading,
      () => {
        const loading = this.$store.state.loading;
        if (loading === true) {
          this.startProgress();
        }
        if (loading === false) {
          this.finishProgress();
        }
      }
    );
  },
  methods: {
    startProgress() {
      this.value = 15;
      this.show = true;
      this.interval = setInterval(() => {
        const ceil = (100 - this.value) / 5;
        const random = Math.floor(Math.random() * ceil);
        this.value += random;
      }, 500);
    },
    async finishProgress() {
      clearInterval(this.interval);
      this.value = 100;

      this.show = false;
    }
  }
};
</script>

<style></style>
