export default {
  login: false,
  signup: false,
  loading: true,
  showProgressLine: false,
  signupProgress: 0,
  landingPageCurrent: null,
  signupForm: {
    userid: "",
    phoneVerifyCount: 0,
    phoneVerifySent: false,
    phoneVerifyCorrect: false,
    phoneVerificationCode: "",
    email: "",
    emailValid: false,
    emailVerificationCode: "",
    emailVerifyCorrect: false,
    emailVerifyCount: 0,
    emailVerifySent: false,
    phone: "",
    tel: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    checkbox: false,
    roleInfo: {}
  },
  changePw: false,
  email: "",
  bazaarList: [],
  workList: [],
  eventBazaarList: [],
  likedArtworks: [],
  cart: [],
  userProfile: {},
  dealList: {
    buyer: [],
    seller: []
  },
  downloadProgress: [],
  imageFiles: [],
  avatarImage: null,
  notifications: [],
  readNotification: null,
  profilePage: null,
  bazaarPageNumber: 1,
  pageTransition: "fade",
  searchParams: {
    eventName: null
  },
  backDestination: {
    show: false,
    path: "",
    name: "",
    callback: null,
    override: false
  },
  setBrowserLanguage: true,
  useridAddressPair: [],
  useridRoleInfoPair: []
};
