export default {
  //
  updateCurrentPage(state, data) {
    state.landingPageCurrent = data;
  },
  changeState(state, data) {
    state.login = data;
  },
  login(state) {
    state.login = true;
  },
  logout(state) {
    state.login = false;
  },
  signup(state) {
    state.signup = !state.signup;
  },
  changePw(state, data) {
    state.changePw = !state.changePw;
    state.email = data;
  },
  updateWorkList(state, data) {
    state.workList = data;
  },
  updateWorkByID(state, data) {
    state.workList = state.workList.filter((elem) => {
      if (elem.workid === data.workid) {
        return data;
      } else {
        return elem;
      }
    });
  },
  updateBazaar(state, data) {
    state.bazaarList = data;
  },
  updateBazaarItem(state, data) {
    let changeIndex = state.bazaarList.findIndex((item) => {
      return item.workid === data.workid;
    });
    state.bazaarList[changeIndex] = data;
  },
  addNewArtwork(state, data) {
    state.bazaarList.push(data);
  },
  likeArtwork(state, workid) {
    state.bazaarList.map((elem) => {
      if (elem.workid === workid) {
        if (state.likedArtworks.includes(workid)) {
          // Unlike
          var index = state.likedArtworks.indexOf(workid);
          state.likedArtworks.splice(index);
          elem.likes = elem.likes - 1;
        } else {
          state.likedArtworks.push(workid);
          elem.likes = elem.likes + 1;
        }
      }
      return elem;
    });
  },
  addToCart(state, workid) {
    if (!state.cart.includes(workid)) {
      state.cart.push(workid);
    }
  },
  editSignupForm(state, payload) {
    for (var key in payload) {
      if (Object.hasOwnProperty.call(state.signupForm, key)) {
        state.signupForm[key] = payload[key];
      }
    }
  },
  signupNextStep(state) {
    if (state.signupProgress < 5) state.signupProgress = state.signupProgress + 1;
  },
  signupPrevStep(state) {
    if (state.signupProgress > 0) state.signupProgress = state.signupProgress - 1;
  },
  signupReset(state) {
    state.signupProgress = 0;
    state.signupForm = {
      username: "",
      phoneVerifyCount: 0,
      phoneVerifySent: false,
      phoneVerifyCorrect: false,
      phoneVerificationCode: "",
      email: "",
      emailValid: false,
      emailVerificationCode: "",
      emailVerifyCorrect: false,
      emailVerifyCount: 0,
      emailVerifySent: false,
      phone: "",
      tel: "",
      mobileNumber: "",
      password: "",
      confirmPassword: "",
      checkbox: false
    };
  },
  showProgressLine(state, data) {
    state.showProgressLine = data;
  },
  addr(state, data) {
    state.addr = data;
  },
  notifications(state, data) {
    state.notifications = data;
  },
  readNotificationID(state, data) {
    state.readNotificationID = data;
  },
  refreshUserProfile(state, data) {
    const now = new Date().getTime();
    if (data.premiumExpiryDate > now) {
      data.isPremium = true;
    } else {
      data.isPremium = false;
    }
    state.userProfile = data;
  },
  setDealListBuyer(state, data) {
    state.dealList.buyer = data;
  },
  setDealListSeller(state, data) {
    state.dealList.seller = data;
  },
  updateSellerDealByID(state, data) {
    state.dealList.seller = state.dealList.seller.map((elem) => {
      if (elem.orderid === data.orderid) {
        return data;
      }
      return elem;
    });
  },
  resetState(state) {
    const getDefaultState = () => {
      return {
        login: false,
        signup: false,
        showProgressLine: false,
        signupProgress: 0,
        signupForm: {
          userid: "",
          phoneVerifyCount: 0,
          phoneVerifySent: false,
          phoneVerifyCorrect: false,
          phoneVerificationCode: "",
          email: "",
          emailValid: false,
          emailVerificationCode: "",
          emailVerifyCorrect: false,
          emailVerifyCount: 0,
          emailVerifySent: false,
          phone: "",
          tel: "",
          mobileNumber: "",
          password: "",
          confirmPassword: "",
          checkbox: false
        },
        changePw: false,
        email: "",
        bazaarList: [],
        workList: [],
        likedArtworks: [],
        cart: [],
        userProfile: {},
        dealList: {
          buyer: [],
          seller: []
        },
        downloadProgress: [],
        imageFiles: [],
        avatarImage: null,
        notifications: [],
        readNotification: null,
        profilePage: null,
        bazaarPageNumber: 1
      };
    };
    Object.assign(state, getDefaultState());
  },
  addImageFile(state, data) {
    if (!state.imageFiles.find((elem) => elem.fileid === data.fileid)) {
      state.imageFiles.push(data);
    }
  },
  removeImageFiles(state) {
    state.imageFiles = [];
  },
  pushNotification(state, data) {
    state.notifications = data;
  },
  readNotification(state, data) {
    state.readNotification = data;
  },
  profilePageChange(state, data) {
    state.profilePage = data;
  },
  bazaarNextPage(state) {
    state.bazaarPageNumber = state.bazaarPageNumber + 1;
  },
  bazaarPrevPage(state) {
    state.bazaarPageNumber = state.bazaarPageNumber - 1;
  },
  bazaarPageReset(state) {
    state.bazaarPageNumber = 1;
  },
  setAvatar(state, data) {
    state.avatarImage = data;
  },
  setTransition(state, data) {
    state.pageTransition = data;
  },
  updateLoadingTask(state, data) {
    let exist = false;
    state.downloadProgress = state.downloadProgress.map((elem) => {
      if (elem.fileid !== data.fileid) {
        return elem;
      } else {
        elem.progress = data.progress;
        exist = true;
        return elem;
      }
    });
    if (!exist) {
      state.downloadProgress.push(data);
    }
  },
  setEventName(state, data) {
    state.searchParams.eventName = data;
  },
  updateEventBazaarList(state, data) {
    state.eventBazaarList = data;
  },
  setGlobalLoading(state, data) {
    state.loading = data;
  },
  setBackDestination(state, data) {
    state.backDestination = data;
  },
  hideBackDestination(state) {
    state.backDestination = {
      show: false,
      path: "",
      name: ""
    };
  },
  setBrowserLanguage(state) {
    state.setBrowserLanguage = state.data;
  },
  addUseridAddressPair(state, data) {
    state.useridAddressPair.push(data);
  },
  addUseridRoleInfoPair(state, data) {
    state.useridRoleInfoPair.push(data);
  }
};
