export async function checkUserUnique(axios, config, userid) {
  const result = await axios.get(`${config.auth_api}/auth/check-userid-unique/${userid}`);
  return result;
}

export async function sendVerifCodeToEmail(axios, config, email, params) {
  let result;
  await axios
    .get(`${config.auth_api}/auth/send-email-verification-code/${email}`, {
      params: {
        userid: params.userid,
        language: params.language
      }
    })
    .then((res) => {
      result = res;
    })
    .catch(function (error) {
      if (error.response) {
        result = error.response;
      } else if (error.request) {
        result = error.request;
      } else {
        result = error;
      }
    });

  return result;
}

export async function sendVerifCodeToPhone(axios, config, phone, params) {
  const result = await axios
    .get(`${config.auth_api}/auth/send-phone-verification-code/${phone}`, {
      params: {
        userid: params.userid,
        language: params.language
      }
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function checkPhoneVerificationCode(axios, config, phone, phoneVerificationCode) {
  const payload = { phone, phoneVerificationCode };

  const result = await axios.post(`${config.auth_api}/auth/check-phone-verification-code`, payload);

  return result;
}

export async function checkEmailVerificationCode(axios, config, email, emailVerificationCode) {
  const payload = { email, emailVerificationCode };
  const result = await axios.post(`${config.auth_api}/auth/check-email-verification-code`, payload);
  return result;
}

export async function signupUser(
  axios,
  config,
  userid,
  email,
  emailVerificationCode,
  phone,
  phoneVerificationCode,
  password,
  language
  // roleInfo
) {
  const payload = {
    userid,
    email,
    emailVerificationCode,
    phone,
    phoneVerificationCode,
    password,
    language,
    roleType: 1
    // roleInfo   #Await debug in backend
  };

  const result = await axios.post(`${config.auth_api}/auth/signup`, payload).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function userSignIn(axios, config, email, password) {
  try {
    let payload = { email, password };
    const opt = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const result = await axios.post(`${config.auth_api}/auth/login`, payload, opt);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getKeyPair(axios, config, token, password) {
  try {
    const options = {
      headers: {
        Authorization: `${token}`
      }
    };
    let payload = {
      password
    };
    const { data: keypairResult } = await axios.post(`${config.auth_api}/auth/key-pair`, payload, options);
    return keypairResult;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function userPasswordReset(axios, config, email, emailCode, phone, phoneCode, newPasswd) {
  let payload = { email, emailCode, phone, phoneCode, newPasswd };
  const result = await axios.post(`${config.auth_api}/auth/passwd`, payload);
  return result;
}

export async function getUserProfile(axios, config, token) {
  try {
    const options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.get(`${config.auth_api}/auth/user-info`, options).then(
      (response) => {
        return response;
      },
      (err) => {
        return err.response;
      }
    );
    if (result.status === 200) {
      if (result.data.roleInfo !== "") {
        result.data.roleInfo = JSON.parse(result.data.roleInfo);
      } else {
        result.data.roleInfo = {}; // Make an empty object for roleinfo
      }
      if (result.data.publicRoleInfo !== "") {
        result.data.publicRoleInfo = JSON.parse(result.data.publicRoleInfo);
      } else {
        result.data.publicRoleInfo = {}; // Make an empty object for roleinfo
      }
    }

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getRoleApplication(axios, config, postfix = "") {
  const result = await axios.get(config.role_api + "/role/application" + postfix);

  return result;
}

export async function updateUserProfile(axios, config, payload, token) {
  const opt = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`
    }
  };

  const result = await axios.put(`${config.auth_api}/auth/user-info`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function changePassword(axios, config, payload, token) {
  const opt = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`
    }
  };

  const result = await axios.post(`${config.auth_api}/auth/change-password`, payload, opt).then(
    (response) => {
      return response;
    },
    (err) => {
      return err.response;
    }
  );

  return result;
}

export async function updateUserInfo(axios, config, payload, token) {
  const opt = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`
    }
  };

  const result = await axios.put(`${config.auth_api}/auth/user-info`, payload, opt);

  return result;
}

export async function updateRoleInfo(axios, config, roleInfo, token) {
  const opt = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`
    }
  };

  const roleInfoPayload = {
    roleInfo: JSON.stringify(roleInfo)
  };

  const result = await axios.put(`${config.auth_api}/auth/user-info`, roleInfoPayload, opt);

  return result;
}

export async function updatePublicRoleInfo(axios, config, roleInfo, token) {
  const opt = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`
    }
  };

  const roleInfoPayload = {
    publicRoleInfo: JSON.stringify(roleInfo)
  };

  const result = await axios.put(`${config.auth_api}/auth/user-info`, roleInfoPayload, opt);

  return result;
}

export async function getPublicRoleInfo(axios, config, userid) {
  try {
    const result = await axios.get(`${config.auth_api}/auth/public-role-info/${userid}`).then(
      (response) => {
        return response;
      },
      (err) => {
        return err.response;
      }
    );
    if (result.status === 200) {
      if (result.data.publicRoleInfo !== "") {
        result.data.publicRoleInfo = JSON.parse(result.data.publicRoleInfo);
      } else {
        result.data.publicRoleInfo = {}; // Make an empty object for roleinfo
      }
    }

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getUseridFromAddress(axios, config, address) {
  try {
    const result = await axios.get(`${config.auth_api}/auth/userid/${address}`);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getReferralRecord(axios, config, referralCode) {
  const result = await axios.get(`${config.auth_api}/auth/referral/${referralCode}`);
  return result;
}

export async function updateReferralCode(axios, config, token, referralCode) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const roleInfoPayload = {
      referralCode
    };

    const result = await axios.put(`${config.auth_api}/auth/user-info`, roleInfoPayload, opt);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function checkPrice(axios, config, token, payload) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const result = await axios.post(`${config.auth_api}/auth/price`, payload, opt);

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function sendResetPasswordVerificationCode(axios, config, email, params) {
  const result = await axios
    .get(`${config.auth_api}/auth/send-reset-password-verification-code/${email}`, {
      params: {
        language: params.language
      }
    })
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function sendResetPasswordRequest(axios, config, payload) {
  const result = await axios
    .post(`${config.auth_api}/auth/reset-password-request`, payload)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}
