import { getUserProfile } from "@/services/authService";
import { getDealList } from "@/services/orderService";
import { displayWorks, getUserWorks } from "@/services/workService";
import config from "../config";
import { updateUserInfo } from "../services/authService";
import { downloadFileFromBFS } from "../services/fileService";
import { displayWorksPage } from "../services/workService";
export default {
  async loadUserProfile(state, { axios, config, token }) {
    const result = await getUserProfile(axios, config, token).catch(() => {});
    if (result.status === 200) {
      state.commit("refreshUserProfile", result.data);
    }
    state.commit("showProgressLine", false);
  },
  async getBazaarList(state, { axios, config }) {
    const sortByDate = (a, b) => {
      return a.createdAt < b.createdAt ? 1 : -1;
    };
    const result = await displayWorks(axios, config);
    if (result.status === 200) {
      let bazaarList = result.data;
      bazaarList = bazaarList.sort(sortByDate);
      state.commit("updateBazaar", bazaarList);
      return result;
    } else {
      return result;
    }
  },
  async getEventBazaarList(state, { axios, config, eventName }) {
    const sortByDate = (a, b) => {
      return a.createdAt < b.createdAt ? 1 : -1;
    };
    let params = {};
    params["event-name-search"] = eventName;
    const result = await displayWorksPage(axios, config, params);
    if (result.status === 200) {
      let bazaarEventList = result.data;
      bazaarEventList = bazaarEventList.sort(sortByDate);
      state.commit("updateEventBazaarList", bazaarEventList);
      return result;
    } else {
      return result;
    }
  },
  async getWorkList(state, { axios, config, token }) {
    const sortByDate = (a, b) => {
      return a.createdAt < b.createdAt ? 1 : -1;
    };
    const result = await getUserWorks(axios, config, token);
    if (result.status === 200) {
      let workList = result.data;
      workList = workList
        .filter((e) => {
          return !e.isDeleted;
        })
        .sort(sortByDate);
      state.commit("updateWorkList", workList);
      return result;
    } else {
      return result;
    }
  },

  async getDealList(state, { axios, config, token }) {
    const setDealSide = async (side) => {
      const result = await getDealList(axios, config, token, side);
      let dealList;
      const sortByDate = (a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
      };
      if (result.status === 200) {
        dealList = result.data.data;

        dealList = dealList
          .filter((elem) => {
            let workIdList = state.getters.getArtworkWorkIdList;
            return workIdList.some((workid) => workid === elem.workid);
          })
          .map((elem) => {
            if (Object.hasOwnProperty.call(elem, "workid")) {
              try {
                elem.artworkInfo = state.getters.getArtworkByID(elem.workid);
                elem.coverPhoto = elem.artworkInfo.coverPhoto;
              } catch (error) {
                elem.coverPhoto = "No image";
              }
            }
            return elem;
          })
          .sort(sortByDate);
      }

      return dealList;
    };
    const buyerDeals = setDealSide("buyer");
    const sellerDeals = setDealSide("seller");
    const result = await Promise.all([buyerDeals, sellerDeals]);
    state.commit("setDealListBuyer", result[0]);
    state.commit("setDealListSeller", result[1]);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  pushNotification(context, payload) {
    context.commit("pushNotification", payload);
  },
  updateArtwork(context, { axios, config, token, workid }) {
    let workLastUpdate = localStorage.getItem("workLastUpdate");
    if (workLastUpdate) {
      workLastUpdate = JSON.parse(workLastUpdate);
    } else {
      workLastUpdate = [];
    }
    let existingRecordIndex = workLastUpdate.findIndex((elem) => {
      return elem.workid === workid;
    });
    let newRecord = {
      workid,
      lastUpdate: new Date().getTime()
    };

    if (existingRecordIndex >= 0) {
      workLastUpdate[existingRecordIndex] = newRecord;
    } else {
      workLastUpdate = workLastUpdate.concat(newRecord);
    }

    localStorage.setItem("workLastUpdate", JSON.stringify(workLastUpdate));
    setTimeout(() => {
      context.dispatch("getWorkList", { axios, config, token });
    }, 10000);
  },
  clearImageURL(context) {
    context.state.imageFiles.forEach((elem) => {
      URL.revokeObjectURL(elem.url);
    });
    context.commit("removeImageFiles");
  },
  async getImageByFileDescriptor(context, { axios, fileDescriptor }) {
    let imageFile = context.getters.getImageFile(fileDescriptor.fileid);
    if (imageFile) {
      return imageFile.url;
    }

    let result = await downloadFileFromBFS(axios, config, fileDescriptor)
      .then((res) => {
        let src = URL.createObjectURL(res.data);
        context.commit("addImageFile", {
          fileid: fileDescriptor.fileid,
          url: src
        });
        return src;
      })
      .catch(() => {
        return "No image";
      });
    return result;
  },
  async addEventNameToUserInfo(context, { axios, config, eventName, token }) {
    if (!eventName || eventName.replace(/\s/g, "") === "") {
      return;
    }
    await context.dispatch("loadUserProfile", { axios, config, token });

    if (context.state.userProfile.roleInfo) {
      let roleInfo = context.state.userProfile.roleInfo;
      let alreadyInHistory = false;
      if (Object.hasOwnProperty.call(roleInfo, "eventNameHistory")) {
        alreadyInHistory = roleInfo.eventNameHistory.some((elem) => {
          return elem === eventName.trim();
        });
        if (!alreadyInHistory) {
          roleInfo.eventNameHistory.push(eventName);
        }
      } else {
        roleInfo.eventNameHistory = [eventName];
      }
      if (!alreadyInHistory) {
        const payload = {
          roleInfo: JSON.stringify(roleInfo)
        };
        await updateUserInfo(axios, config, payload, token);
        context.dispatch("loadUserProfile", { axios, config, token });
      }
    }
  },
  async removeEventNameToUserInfo(context, { axios, config, eventName, token }) {
    await context.dispatch("loadUserProfile", { axios, config, token });

    if (context.state.userProfile.roleInfo) {
      let roleInfo = context.state.userProfile.roleInfo;
      if (Object.hasOwnProperty.call(roleInfo, "eventNameHistory")) {
        const index = roleInfo.eventNameHistory.findIndex((elem) => {
          return elem === eventName;
        });
        if (index > -1) {
          roleInfo.eventNameHistory.splice(index, 1);
        }
        const payload = {
          roleInfo: JSON.stringify(roleInfo)
        };
        await updateUserInfo(axios, config, payload, token);
        context.dispatch("loadUserProfile", { axios, config, token });
      }
    }
  },
  async addEventCodeToUserInfo(context, { axios, config, eventCode, token }) {
    await context.dispatch("loadUserProfile", { axios, config, token });

    if (context.state.userProfile.roleInfo) {
      let roleInfo = context.state.userProfile.roleInfo;
      if (Object.hasOwnProperty.call(roleInfo, "eventCodeHistory")) {
        const alreadyInHistory = roleInfo.eventCodeHistory.some((elem) => {
          return elem === eventCode;
        });
        if (!alreadyInHistory) {
          roleInfo.eventCodeHistory.push(eventCode);
        }
      } else {
        roleInfo.eventCodeHistory = [eventCode];
      }
      const payload = {
        roleInfo: JSON.stringify(roleInfo)
      };
      await updateUserInfo(axios, config, payload, token);
      context.dispatch("loadUserProfile", { axios, config, token });
    }
  },
  async getPrivateLimit(context, { axios, config, token }) {
    await context.dispatch("getWorkList", { axios, config, token });

    let privateWorks = context.state.workList.filter((elem) => {
      if (elem.creatorAddress !== elem.ownerAddress) {
        return false;
      }
      if (elem.workStatus === 0 || elem.workStatus === 1) {
        return true;
      }
      return false;
    });
    if (context.state.userProfile.isPremium) {
      return 0;
    } else {
      return privateWorks.length;
    }
  }
};
