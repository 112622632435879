// src/plugins/vuetify.js

import Vue from "vue";
import Vuetify from "vuetify/lib";

import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

// // Translation provided by Vuetify (javascript)
// import zhHans from "vuetify/es5/locale/zh-Hans"

// // Translation provided by Vuetify (typescript)
// import zhHant from "vuetify/es5/locale/zh-Hant"

// // Translation provided by Vuetify (typescript)
// import en from "vuetify/es5/locale/en"

Vue.component("my-component", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "en";
    }
  }
});

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#63a3c3",
        secondary: "#8bb35d",
        accent: "#8c9eff",
        error: "#b71c1c"
      }
    }
  }
};

export default new Vuetify(opts);
