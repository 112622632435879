let config;

const production_config = {
  frontend: "https://tectechain.com",
  files_api: "https://tectechain.com:8031",
  auth_api: "https://tectechain.com:8031",
  ws_api: "wss://tectechain.com:8031",
  works_api: "https://tectechain.com:8041",
  events_api: "https://tectechain.com:8031",
  backend_api: "https://tectechain.com:8061",
  vision_api: "https://tectechain.com:8031",
  landing_api: "https://tectechain.com:8031",
  contract_api: "https://tectechain.com:8090",
  paypal_sdk:
    "https://www.paypal.com/sdk/js?client-id=AXF5H33rvqwZDN2LwLZM37jfeRWPjLjkbvHoDpKUqg9U1kpjyr7CBfV6EvVxz5Rs2BOx03sAmH5s29Nh",
  transaction_fee: 0.15,
  payment_gateway_fee: 0,
  paypal: false,
  fps: true,
  stripe: true,
  stripe_pk:
    "pk_live_51HAASsGyox3TzGvjO1LAGbnhObx7wftAyuUxkgAZCkhl7bl27aToTcOz6skdPYFVOzQzeDY044GsaR1xmzgQZ7cm00XPOaVCTO",
  cookieMessage: false,
  privateWorksLimit: 3,
  development_lock: false,
  comments: false,
  version_name: "Production"
};

const preview_config = {
  frontend: "https://tectechain.com:8080",
  files_api: "https://tectechain.com:8031",
  auth_api: "https://tectechain.com:8031",
  ws_api: "wss://tectechain.com:8031",
  works_api: "https://tectechain.com:8041",
  events_api: "https://tectechain.com:8031",
  backend_api: "https://tectechain.com:8061",
  vision_api: "https://tectechain.com:8031",
  landing_api: "https://tectechain.com:8031",
  contract_api: "https://tectechain.com:8090",
  paypal_sdk:
    "https://www.paypal.com/sdk/js?client-id=AXF5H33rvqwZDN2LwLZM37jfeRWPjLjkbvHoDpKUqg9U1kpjyr7CBfV6EvVxz5Rs2BOx03sAmH5s29Nh",
  transaction_fee: 0.15,
  payment_gateway_fee: 0,
  paypal: false,
  fps: true,
  stripe: true,
  stripe_pk:
    "pk_live_51HAASsGyox3TzGvjO1LAGbnhObx7wftAyuUxkgAZCkhl7bl27aToTcOz6skdPYFVOzQzeDY044GsaR1xmzgQZ7cm00XPOaVCTO",
  cookieMessage: false,
  privateWorksLimit: 3,
  development_lock: true,
  comments: false,
  version_name: "Preview"
};

const dev_config = {
  frontend: "https://ip-oh.com:8080",
  files_api: "https://ip-oh.com:8031",
  auth_api: "https://ip-oh.com:8031",
  ws_api: "wss://ip-oh.com:8031",
  works_api: "https://ip-oh.com:8041",
  events_api: "https://ip-oh.com:8031",
  backend_api: "https://ip-oh.com:8061",
  vision_api: "https://ip-oh.com:8031",
  landing_api: "https://ip-oh.com:8031",
  contract_api: "https://ip-oh.com:8090",
  paypal_sdk:
    "https://www.paypal.com/sdk/js?client-id=ARtIIKqEIFjnJXvAa8rOv7iZHA25_ry_aZ64v9pcw37SCth57clZjP-8a_i9RbQyVMq1BXhTISsSHede",
  transaction_fee: 0.15,
  payment_gateway_fee: 0,
  paypal: false,
  fps: true,
  stripe: true,
  stripe_pk:
    "pk_test_51HAASsGyox3TzGvjWlSlek4p0zCf3w1BW5MF8T8JQ8PdduVcBiwX98ubz8IbdEwDzO91QVE5ltlWbwmG3259KFuP00kQ5MWjwg",
  cookieMessage: true,
  privateWorksLimit: 3,
  development_lock: true,
  comments: false,
  version_name: "Development"
};

if (process.env.NODE_ENV === "production" || process.env.VUE_APP_ENV === "production") {
  // Run on production servers
  config = production_config;
} else {
  // Run on development servers
  config = dev_config;
}

if (process.env.VUE_APP_ENV === "preview") {
  // Preview settings override
  config = preview_config;
}

export default config;
