export default {
  fullName: (state) => {
    return state.firstName + " " + state.lastName;
  },
  getArtworkList: (state) => (number) => {
    return state.bazaarList.slice(0, number);
  },
  getArtworkSorted: (state) => (number) => {
    const sort = (items) => {
      return items.sort((a, b) => b.publication_date - a.publication_date);
    };
    return sort(state.bazaarList).slice(0, number);
  },
  getArtworkByID: (state) => (workid) => {
    return state.bazaarList.find((elem) => {
      return elem.workid === workid;
    });
  },
  getWorkList: (state) => {
    return state.workList;
  },
  getWorkByID: (state) => (workid) => {
    return state.getters.getWorkList.find((elem) => {
      return elem.workid === workid;
    });
  },
  getArtworkWorkIdList: (state) => {
    let list = [];
    state.bazaarList.forEach((elem) => {
      list.push(elem.workid);
    });
    return list;
  },
  getArtworkFromID: (state) => (workid) => {
    return state.bazaarList.find((elem) => elem.workid === workid);
  },
  getSimilarArtworks: (state) => (workid, artworkType, artworkClass, length) => {
    const getArtworkType = (elem) => {
      if (Object.hasOwnProperty.call(elem.unmanagedInfo, "artworkType")) {
        return elem.unmanagedInfo.artworkType;
      } else if (Object.hasOwnProperty.call(elem.workInfo, "artworkType")) {
        return elem.workInfo.artworkType;
      } else {
        return null;
      }
    };
    const getArtworkClass = (elem) => {
      if (Object.hasOwnProperty.call(elem.unmanagedInfo, "artworkClass")) {
        return elem.unmanagedInfo.artworkClass;
      } else if (Object.hasOwnProperty.call(elem.workInfo, "artworkClass")) {
        return elem.workInfo.artworkClass;
      } else {
        return null;
      }
    };
    let similar = state.bazaarList
      .filter((elem) => {
        return elem.workid !== workid;
      })
      .map((elem) => {
        let itemArtworkType = getArtworkType(elem);
        let itemArtworkClass = getArtworkClass(elem);
        if (!itemArtworkType && itemArtworkType !== 0) {
          elem.similarity = -2;
          return elem;
        } else {
          elem.similarity = 0;
          if (itemArtworkType === artworkType) {
            elem.similarity = 1 + Math.random() * 0.9;

            if (itemArtworkClass === artworkClass) {
              elem.similarity = 3 + Math.random() * 0.9;
            } else if (itemArtworkClass || itemArtworkClass === 0) {
              elem.similarity = 2 + Math.random() * 0.9;
            }
          }
          return elem;
        }
      });
    function compare(a, b) {
      return b.similarity - a.similarity;
    }

    similar.sort(compare);

    return similar.slice(0, length);
  },
  navigateBazaarWork: (state) => (workid, direction) => {
    let bazaarList;
    if (state.searchParams.eventName) {
      bazaarList = state.eventBazaarList;
    } else {
      bazaarList = state.bazaarList;
    }
    const index = bazaarList.findIndex((elem) => elem.workid === workid);
    let moveToItemWorkid;
    if (direction === "prev") {
      if (index > 0) {
        moveToItemWorkid = bazaarList[index - 1].workid;
      }
    } else {
      if (bazaarList.length > index + 1) {
        moveToItemWorkid = bazaarList[index + 1].workid;
      }
    }
    return moveToItemWorkid;
  },
  getDealByOrderID: (state) => (orderid) => {
    let dealList = state.dealList.buyer;
    dealList = dealList.concat(state.dealList.seller);
    return dealList.find((elem) => elem.orderid === orderid);
  },
  alreadyInCart: (state) => (workid) => {
    if (state.cart.includes(workid)) {
      return true;
    }
    return false;
  },
  loggedIn: (state) => {
    return state.login;
  },
  profileImage: (state) => {
    try {
      let profilePicture = state.userProfile.publicRoleInfo.iconPhoto.fileDescriptor;
      if (profilePicture) {
        return profilePicture;
      } else {
        return "No image";
      }
    } catch {
      return "No image";
    }
  },
  getImageFile: (state) => (fileid) => {
    let imageFile = state.imageFiles.find((elem) => elem.fileid === fileid);
    if (imageFile) {
      return imageFile;
    } else {
      return null;
    }
  }
};
