import Vue from "vue";
import Router from "vue-router";
// import Home from "../views/Home";
import paths from "./paths";
import store from "@/store";
import VueSession from "vue-session";
Vue.use(VueSession);
const session = Vue.prototype.$session;
import axios from "axios";
import { getUserProfile } from "../services/authService";
import config from "../config";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL || "http://localhost:8080",
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      const options = { selector: to.hash, offset: { x: 0, y: 60 } };
      if (to.path !== from.path) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(options);
          }, 1000);
        });
      } else {
        return options;
      }
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (from.path !== "/maintenance") {
    axios.get(`${config.frontend}/static/config.json`, {}).then((res) => {
      if (res.data.maintenance) {
        next({
          path: "/maintenance"
        });
      }
    });
  }
  if (to.path !== from.path) {
    store.commit("setGlobalLoading", true);
  }

  let loggedIn = session.exists("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      localStorage.setItem("redirect", JSON.stringify(to));
      next({
        name: "Login",
        path: "/login"
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/login" && from.path !== "/platform/bazaar" && loggedIn) {
      next({
        path: "/platform/bazaar"
      });
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  store.commit("setGlobalLoading", false);
  store.commit("hideBackDestination");
});

if (process.env.VUE_APP_ENV === "staging" || process.env.VUE_APP_ENV === "preview") {
  router.beforeEach(async (to, from, next) => {
    let devToken = localStorage.getItem("devToken");

    let loggedIn;
    if (devToken) {
      loggedIn = await getUserProfile(axios, config, devToken).then((res) => {
        return res.data.roleType === 0;
      });
    } else {
      loggedIn = false;
    }

    if (!loggedIn) {
      localStorage.removeItem("devToken");
    }

    if (to.matched.some((record) => record.meta.requiresDevAuth)) {
      if (!loggedIn) {
        next({
          path: "/dev-login"
        });
      } else {
        next();
      }
    } else {
      if (to.path === "/dev-login" && loggedIn) {
        next({
          path: "/"
        });
      }
      next();
    }
  });
}

export default router;
