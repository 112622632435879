export async function getUserWorks(axios, config, token) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios
    .get(`${config.works_api}/works`, options)
    .then((res) => {
      if (res.data) {
        res.data = res.data.map((elem) => {
          try {
            if (elem.workInfo === "") {
              elem.workInfo = "{}";
            }
            elem.workInfo = JSON.parse(elem.workInfo);
            if (elem.unmanagedInfo === "") {
              elem.unmanagedInfo = "{}";
            }
            elem.unmanagedInfo = JSON.parse(elem.unmanagedInfo);

            return elem;
          } catch (error) {
            (error) => error;
          }
        });
      } else {
        res.data = [];
      }
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function displayWorks(axios, config) {
  const result = await axios
    .get(`${config.works_api}/works/display`)
    .then((res) => {
      res.numberOfWorks = res.data.totalWorks;
      res.data = res.data.works.map((elem) => {
        try {
          if (elem.workInfo === "") {
            elem.workInfo = "{}";
          }
          elem.workInfo = JSON.parse(elem.workInfo);
          if (elem.unmanagedInfo === "") {
            elem.unmanagedInfo = "{}";
          }
          elem.unmanagedInfo = JSON.parse(elem.unmanagedInfo);

          return elem;
        } catch (error) {
          (error) => error;
        }
      });
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });

  result.data = await filterHiddenWorks(result.data, axios, config);

  return result;
}

export async function displayWorksPage(axios, config, params) {
  const result = await axios
    .get(`${config.works_api}/works/display`, {
      params: params
    })
    .then((res) => {
      if (res.data) {
        res.numberOfWorks = res.data.totalWorks;
        res.data = res.data.works.map((elem) => {
          try {
            if (elem.workInfo === "") {
              elem.workInfo = "{}";
            }
            elem.workInfo = JSON.parse(elem.workInfo);
            if (elem.unmanagedInfo === "") {
              elem.unmanagedInfo = "{}";
            }
            elem.unmanagedInfo = JSON.parse(elem.unmanagedInfo);

            return elem;
          } catch (error) {
            (error) => error;
          }
        });
      }

      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });

  result.data = await filterHiddenWorks(result.data, axios, config);
  return result;
}

export async function displayLandingWorks(axios, config) {
  const result = await axios
    .get(`${config.works_api}/works/landing`)
    .then((res) => {
      res.numberOfWorks = res.data.totalWorks;
      res.data = res.data.works.map((elem) => {
        try {
          if (elem.workInfo === "") {
            elem.workInfo = "{}";
          }
          elem.workInfo = JSON.parse(elem.workInfo);
          if (elem.unmanagedInfo === "") {
            elem.unmanagedInfo = "{}";
          }
          elem.unmanagedInfo = JSON.parse(elem.unmanagedInfo);

          return elem;
        } catch (error) {
          (error) => error;
        }
      });
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });

  result.data = await filterHiddenWorks(result.data, axios, config);
  return result;
}

export async function displayWorkByWorkid(axios, config, workid, token) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios
    .get(`${config.works_api}/works/${workid}`, options)
    .then((res) => {
      try {
        if (res.data.workInfo === "") {
          res.data.workInfo = "{}";
        }
        res.data.workInfo = JSON.parse(res.data.workInfo);
        if (res.data.unmanagedInfo === "") {
          res.data.unmanagedInfo = "{}";
        }
        res.data.unmanagedInfo = JSON.parse(res.data.unmanagedInfo);

        return res;
      } catch (error) {
        (error) => error;
      }
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function uploadWorks(axios, config, token, payload) {
  const options = {
    headers: {
      Authorization: token
    }
  };
  const result = await axios
    .post(`${config.works_api}/works`, payload, options)
    .then((res) => {
      if (res.data.workInfo === "") {
        res.data.workInfo = "{}";
      }
      res.data.workInfo = JSON.parse(res.data.workInfo);
      if (res.data.unmanagedInfo === "") {
        res.data.unmanagedInfo = "{}";
      }
      res.data.unmanagedInfo = JSON.parse(res.data.unmanagedInfo);

      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function uploadWorkInfo(axios, config, token, workid, payload) {
  let options = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios
    .put(`${config.works_api}/works/${workid}`, payload, options)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function uploadUnmanagedWorkInfo(axios, config, token, workid, payload) {
  let options = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios
    .put(`${config.works_api}/works/unmanagedInfo/${workid}`, payload, options)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function publishToBazaar(axios, config, token, workid, payload) {
  let options = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios
    .put(`${config.works_api}/works/publish/${workid}`, payload, options)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function likeWork(axios, config, token, workid, payload) {
  let options = {
    headers: {
      Authorization: token
    }
  };

  const result = await axios
    .put(`${config.works_api}/works/like/${workid}`, payload, options)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
  return result;
}

export async function deleteWork(axios, config, token, workid, keypair) {
  let options = {
    headers: {
      Authorization: token
    }
  };
  try {
    if (keypair) {
      const payload = keypair;
      const result = await axios
        .put(`${config.works_api}/works/delete/${workid}`, payload, options)
        .then((res) => {
          return res;
        })
        .catch(function (error) {
          if (error.response) {
            return error.response;
          } else if (error.request) {
            return error.request;
          } else {
            return error;
          }
        });
      return result;
    } else {
      return;
    }
  } catch (error) {
    return error;
  }
}

export async function getAllEvents(axios, config, token, getFromApi, getFromKeyValue) {
  let works = {};
  works.status = 200;
  works.data = [];
  if (getFromKeyValue) {
    const keyValue = await retrieveFrontendKeyValue(axios, config);
    works.data = works.data.concat(keyValue.data.landingPageEvents);
  }
  if (getFromApi) {
    let options = {};
    if (token) {
      options = {
        headers: {
          Authorization: token
        }
      };
    }

    const result = await axios.get(`${config.events_api}/events`, options);

    result.data = result.data.map((elem) => {
      if (elem.eventObject) {
        elem.eventObject = {};
        return elem;
      }
      try {
        elem.eventObject = JSON.parse(elem.eventObject);
      } catch {
        elem.eventObject = {};
      }
      return elem;
    });
    works.data = works.data.concat(result.data);
  }

  return works;
}

export async function checkEvent(axios, config, eventCode) {
  try {
    const result = await axios.get(`${config.events_api}/events/check/${eventCode}`);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getWorkEventCodes(axios, config, workid, token) {
  try {
    let options = {
      headers: {
        Authorization: token
      }
    };
    const result = await axios.get(`${config.events_api}/works/events/${workid}`, options);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getLandingInfo(axios, config) {
  const result = await axios.get(`${config.landing_api}/landing-info`);
  result.data = result.data.map((elem) => {
    try {
      if (elem.landingInfo === "") {
        elem.workInfo = "{}";
      }
      elem.landingInfo = JSON.parse(elem.landingInfo);
      return elem;
    } catch (error) {
      (error) => error;
    }
  });

  return result;
}

export function retrieveLastUpdate(workid, timenow) {
  let workLastUpdate = localStorage.getItem("workLastUpdate");
  if (workLastUpdate) {
    workLastUpdate = JSON.parse(workLastUpdate);
  } else {
    return 100;
  }
  let record = workLastUpdate.find((elem) => elem.workid === workid);
  if (record) {
    return (timenow - record.lastUpdate) / 200;
  } else {
    return 100;
  }
}

export async function retrieveFrontendKeyValue(axios, config) {
  const result = await axios.get(`${config.frontend}/static/event-config.json`, {});
  return result;
}

async function filterHiddenWorks(workArray, axios, config) {
  const filterList = await axios.get(`${config.frontend}/static/hidden-works.json`, {});
  if (filterList.status !== 200) {
    return workArray;
  }

  const filterWorkArray = workArray.filter((elem) => {
    return filterList.data.hiddenWorks.indexOf(elem.workid) === -1;
  });
  return filterWorkArray;
}
