import Vue from "vue";
import App from "./App.vue";
// import VueGtag from "vue-gtag";

import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "@/store";
import axios from "axios";

import VueI18n from "vue-i18n";
import messages from "@/i18n/messages.json";
import VueSanitize from "vue-sanitize";
import VueCurrencyInput from "vue-currency-input";
import "./registerServiceWorker";
import VueScreen from "vue-screen";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "./scss/main.scss";
import FlagIcon from "vue-flag-icon";
import VueChatScroll from "vue-chat-scroll";
import vuescroll from "vuescroll";

// import config from "./config";

let defaultOptions = {
  allowedTags: ["a", "b", "table", "tr", "td", "span", "br", "h1", "h2", "h3", "h4", "h5", "h6", "p", "img", "iframe"],
  allowedAttributes: {
    a: ["href"],
    table: ["style"],
    td: ["style"],
    span: ["style"],
    img: ["src", "style"]
  }
};
Vue.use(VueSanitize, defaultOptions);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  messages
});

Vue.use(VueScreen);

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.use(FlagIcon);
Vue.use(VueChatScroll);

// You can set global config here.
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      locking: true
    },
    scrollPanel: {
      scrollingX: false,
      easing: "easeInOutQuad"
    },

    bar: { keepShow: true, size: "8px", background: "#63a3c3" },
    rail: { size: "8px", background: "#f6f8f9", opacity: 1 }
  }
});

// Vue.use(
//   VueGtag,
//   {
//     bootstrap: !config.cookieMessage,
//     config: { id: process.env.NODE_ENV === "production" ? "G-G3GD4ND3GG" : "G-0K4J99L0R8" }
//   },
//   router
// );

// const options = {
//   name: "_blank",
//   specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
//   styles: [
//     "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
//     "https://use.typekit.net/bim3klu.css"
//   ]
// };

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

const pluginOptions = {
  /* see config reference */
  globalOptions: {
    currency: "USD",
    locale: "en",
    valueAsInteger: false,
    distractionFree: true,
    precision: 2,
    autoDecimalMode: false,
    valueRange: { min: 0 },
    allowNegative: false
  }
};
Vue.use(VueCurrencyInput, pluginOptions);

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
