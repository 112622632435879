let mime = require("mime-types");

export async function saveFilesToBFS(axios, config, fileList) {
  try {
    let fileIDs = [];

    for (let file of fileList) {
      if (file.fID === undefined) {
        const bodyFormData = new FormData();

        bodyFormData.append("file", file);
        bodyFormData.set("days", 60);
        bodyFormData.set("storage", 3);

        let retry = 10;

        while (retry) {
          const { data: fileData } = await axios({
            method: "post",
            url: `${config.file_api}/file`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
          });

          if (fileData && fileData.data && fileData.data.afid) {
            retry = 0;
            fileIDs.push({
              afid: fileData.data.afid,
              name: file.name,
              type: file.type
            });
          } else {
            retry--;
          }
        }
      } else {
        // the file has been already stored in the database with fID
        fileIDs.push({ afid: file.fID, name: file.name, type: file.type });
      }
    }

    return fileIDs;
  } catch (ex) {
    (e) => e;
  }
}

export async function downloadFileFromBFS(axios, config, fileDescriptor, contentType) {
  try {
    const opt = {
      responseType: "blob"
    };
    if (contentType) {
      opt.headers = {
        "content-type": contentType
      };
    } else {
      contentType = mime.lookup(fileDescriptor.fileType);
    }
    const payload = { fileDescriptor };
    const result = await axios.post(`${config.files_api}/files/download`, payload, opt).then((res) => {
      res.data = res.data.slice(0, res.data.size, contentType);
      return res;
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

function resizeBase64Img(dataURL, maxWidth, maxHeight) {
  return new Promise((done) => {
    var img = new Image();
    img.onload = () => {
      var scale, newWidth, newHeight, canvas, ctx;
      if (img.width < maxWidth) {
        scale = maxWidth / img.width;
      } else {
        scale = maxHeight / img.height;
      }
      newWidth = img.width * scale;
      newHeight = img.height * scale;
      canvas = document.createElement("canvas");
      canvas.height = newHeight;
      canvas.width = newWidth;
      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
      done(canvas.toDataURL());
    };
    img.src = dataURL;
  });
}

export async function downloadImageFromBFS(axios, config, fileDescriptor) {
  const opt = {
    responseType: "blob",
    encoding: "binary"
  };
  const payload = { fileDescriptor };
  return axios
    .post(`${config.files_api}/files/download`, payload, opt)
    .then((res) => {
      res.data = URL.createObjectURL(res.data);
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error;
      }
    });
}

export async function downloadBase64ImageFromBFS(axios, config, fileDescriptor, contentType, doNotResize) {
  const opt = {
    responseType: "arraybuffer",
    encoding: "binary"
  };
  if (fileDescriptor.fileid === "") {
    return "";
  }
  const payload = { fileDescriptor };

  return axios.post(`${config.files_api}/files/download`, payload, opt).then(async (response) => {
    if (!contentType) {
      contentType = response.headers["content-type"];
    }
    let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
    let dataURL = `data:${contentType};base64,${image}`;
    if (!doNotResize) {
      let resizedImage = await resizeBase64Img(dataURL, 1000, 1000);
      return resizedImage;
    } else {
      return dataURL;
    }
  });
}

export async function downloadPrivateBase64ImageFromBFS(axios, config, fileDescriptor, keypair, contentType) {
  if (fileDescriptor.fileid === "") {
    return "";
  }
  const payload = { fileDescriptor, privateKey: keypair.privateKey };

  let axiosOpts = {
    method: "post",
    url: `${config.files_api}/files/private-download`,
    data: payload,
    headers: { crossdomain: true },
    responseType: "arraybuffer",
    encoding: "binary"
  };

  return axios(axiosOpts).then(async (response) => {
    if (!contentType) {
      contentType = response.headers["content-type"];
    }
    let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
    let dataURL = `data:${contentType};base64,${image}`;
    return dataURL;
  });
}

export async function uploadCoverPhotoToBFS(axios, config, file, token) {
  try {
    const bodyFormData = file;
    bodyFormData.set("days", 60);
    bodyFormData.set("storage", 3);
    bodyFormData.set("createThumbnail", true);
    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: token }
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function uploadFileToBFS(axios, config, file, token) {
  try {
    const bodyFormData = file;

    bodyFormData.set("days", 60);
    bodyFormData.set("storage", 3);
    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: token }
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function uploadPeerFileToBFS(axios, config, file, token, userAddress) {
  const bodyFormData = file;
  bodyFormData.set("storage", 3);
  bodyFormData.set("userAddresses", JSON.stringify(userAddress));
  bodyFormData.set("isPersistent", true);

  try {
    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/peer-upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: token }
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function uploadPrivateFileToBFS(axios, config, file, token) {
  try {
    const bodyFormData = file;

    bodyFormData.set("days", 60);
    bodyFormData.set("storage", 3);
    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/private-upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: token }
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function downloadPeerFileFromBFS(axios, config, fileDescriptor, keypair) {
  let contentType = mime.lookup(fileDescriptor.fileType);
  try {
    const payload = keypair;
    payload.fileDescriptor = fileDescriptor;
    // const opt = {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     crossdomain: "true"
    //   }
    // };
    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/peer-download`,
      data: payload,
      headers: { crossdomain: true, "content-type": contentType },
      responseType: "blob"
    }).then((res) => {
      res.data = res.data.slice(0, res.data.size, contentType);
      return res;
    });

    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function downloadPrivateFileFromBFS(axios, config, fileDescriptor, keypair) {
  let contentType = mime.lookup(fileDescriptor.fileType);
  const payload = { fileDescriptor, privateKey: keypair.privateKey };
  // const opt = {
  //   headers: {
  //     "Access-Control-Allow-Origin": "*",
  //     crossdomain: "true"
  //   }
  // };

  const result = await axios({
    method: "post",
    url: `${config.files_api}/files/private-download`,
    data: payload,
    headers: { crossdomain: true, "Content-Type": contentType },
    responseType: "blob"
  }).then((res) => {
    res.data = res.data.slice(0, res.data.size, contentType);
    return res;
  });

  return result;
}

export async function downloadPrivateFileAndSave(axios, config, fileDescriptor, keypair) {
  let contentType = mime.lookup(fileDescriptor.fileType);
  const payload = { fileDescriptor, privateKey: keypair.privateKey };
  return axios(`${config.files_api}/files/private-download`, {
    method: "post",
    data: payload,
    headers: { crossdomain: true, "Content-Disposition": "attachment", "Content-Type": contentType },
    responseType: "blob"
  }).then((res) => {
    res.data = res.data.slice(0, res.data.size, contentType);
    return res;
  });
}

export async function uploadProtectedFileToBFS(axios, config, file, token) {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.set("days", 60);
    bodyFormData.set("storage", 3);

    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/protected-upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", Authorization: token }
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function downloadProtectedFileFromBFS(axios, config, fileDescriptor, token, keypair) {
  let contentType = mime.lookup(fileDescriptor.fileType);
  try {
    let payload = {
      fileDescriptor: fileDescriptor,
      privateKey: keypair.privateKey
    };

    const result = await axios({
      method: "post",
      url: `${config.files_api}/files/protected-download`,
      data: payload,
      headers: { Authorization: token, crossdomain: true },
      responseType: "blob"
    }).then((res) => {
      res.data = res.data.slice(0, res.data.size, contentType);
      return res;
    });
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function checkIdImageVision(axios, config, id) {
  const bodyFormData = new FormData();
  bodyFormData.append("image", id.file);
  try {
    if (id.type === 2) {
      const result = await axios({
        method: "post",
        url: `${config.vision_api}/api/vision/getPassportInfo`,
        data: bodyFormData
      });
      return result;
    } else if (id.type === 0) {
      const result = await axios({
        method: "post",
        url: `${config.vision_api}/api/vision/gethkidInfo`,
        data: bodyFormData
      });
      return result;
    } else if (id.type === 1) {
      const result = await axios({
        method: "post",
        url: `${config.vision_api}/api/vision/getTravelPermitInfo`,
        data: bodyFormData
      });
      return result;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}
