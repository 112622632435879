<template>
  <v-app>
    <v-card
      v-if="development"
      color="primary"
      style="
        position: fixed;
        width: 100vw;
        bottom: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        border-radius: 0px;
      "
    >
      {{ versionName }} build version: {{ version }}
    </v-card>
    <component v-bind:is="layout">
      <transition :name="transitionName">
        <router-view :layout.sync="layout" :key="$route.params.workid" />
      </transition>
    </component>
    <overlay-loading />
    <v-snackbar
      :value="showCookiePreference && cookieMessage"
      :timeout="-1"
      light
      vertical
      rounded=""
      style="padding-bottom: 0px"
    >
      {{ $t("dialogMsg.cookies.message") }}
      <a href="/cookies">{{ $t("dialogMsg.cookies.moreInformation") }}</a>
      <template v-slot:action="{ attrs }">
        <v-btn text color="primary" v-bind="attrs" @click="enablePlugin"> {{ $t("dialogMsg.cookies.accept") }} </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-snackbar bottom :value="updateExists" :timeout="-1" light>
      {{ $t("swUpdate.text") }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="refreshApp" v-bind="attrs"> {{ $t("swUpdate.button") }} </v-btn>
      </template>
    </v-snackbar> -->
  </v-app>
</template>

<script>
import update from "@/utils/update.js";
import packageFile from "@/../package.json";
import OverlayLoading from "@/components/Common/OverlayLoading.vue";
import config from "./config";

export default {
  name: "App",
  components: {
    OverlayLoading
  },
  data() {
    return {
      layout: `div`,
      transitionName: null,
      showCookiePreference: null
    };
  },
  mounted() {
    let cookiePreference = localStorage.getItem("cookiePreference");
    if (cookiePreference) {
      this.enablePlugin();
    } else {
      this.showCookiePreference = true;
    }
    if (this.updateExists) {
      this.refreshApp();
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const lang = urlParams.get("lang");
    const langExist = ["en", "zhHant", "zhHans"].findIndex((e) => e === lang);
    if (lang && langExist > 0) {
      this.$i18n.locale = lang;
    }
  },
  mixins: [update],
  beforeCreate() {
    window.addEventListener("unload", this.reload);
  },
  methods: {
    enablePlugin() {
      localStorage.setItem("cookiePreference", true);
      this.showCookiePreference = false;
    }
  },
  watch: {
    $route(to) {
      let name;
      if (to.meta.translationKey) {
        name = this.$t(to.meta.translationKey).concat(" - ").concat(this.$t("tectechainName"));
      } else {
        name = this.$t("tectechainName");
      }

      document.title = name || "Tectechain";
      // const toDepth = to.path.split("/").length;
      // const fromDepth = from.path.split("/").length;
      const transition = this.$store.state.pageTransition;
      if (transition && this.$screen.width < 1264) {
        this.transitionName = transition;
        this.$store.commit("setTransition", "fade");
      } else {
        this.transitionName = "fade";
      }
    }
  },
  computed: {
    development() {
      return config.development_lock;
    },
    versionName() {
      return config.version_name;
    },
    version() {
      return packageFile.version;
    },
    cookieMessage() {
      return config.cookieMessage;
    }
  }
};
</script>
